import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ERC20RouterSDK } from '@stichting-allianceblock-foundation/abridge-sdk';
import { Icon } from '@stichting-allianceblock-foundation/components';
import { Tooltip } from 'components/Tooltips';
import { useGlobalContext } from 'hooks/useGlobalContext';
import { addAsset, getProvider } from 'utils/metamask';

import ClaimButton from '../../ClaimButton/ClaimButton';

import './ClaimListingWeb.scss';

const ClaimListingWeb = ({ entry }: { entry: FormattedTransfer }) => {
  const { t } = useTranslation();
  const { sdk } = useGlobalContext();
  const [buttonStatus, setButtonStatus] = useState<string | undefined>();

  const trimmedNumber: string = entry.bridgedToken.amount.substring(0, 8);

  const tokenSymbolName: string = entry.bridgedToken.details.symbol;

  async function addBridgedTokenToWallet(
    tokenType: MetamaskAssetParamsType,
    tokenOptions: MetamaskAssetParamsOptions,
  ) {
    try {
      if (sdk) {
        const tokenAddress: string = await sdk
          .dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME)
          .getWrappedForNativeToken(entry.nativeChainId, entry.nativeToken);
        const options = { ...tokenOptions, address: tokenAddress };
        const provider = getProvider();
        await addAsset(tokenType, options, provider);
      }
    } catch (err) {
      console.error('An error occurred while trying to add the token.');
    }
  }

  useEffect(() => {
    setButtonStatus(entry.status);
  }, [entry.status]);

  const editIcon = (status: string) => {
    return status === t('claimPage:buttonStatus.notClaimed') || status === 'false' ? (
      <Icon name="edit-claim" size={20} color="brand-primary" />
    ) : status === t('claimPage:buttonStatus.claiming') ? (
      <Icon name="staking-expiration-time" size={20} color="brand-primary" />
    ) : status === t('claimPage:buttonStatus.claimed') || status === 'true' ? (
      <Icon name="edit-finalize" size={20} color="brand-primary" />
    ) : null;
  };

  return (
    <div className="entryTableWrapper d-flex justify-content-between align-items-center mt-3 mb-2 mx-4">
      <div className="icon-wrapper d-flex align-items-center justify-content-center">
        {editIcon(buttonStatus ? buttonStatus : '')}
      </div>
      <div className="info d-flex justify-content-between">
        <div className="from-icon-wrapper d-flex align-items-center">
          {entry?.sourceNetwork?.chainIcon && (
            <Icon name={entry?.sourceNetwork?.chainIcon} size={20} />
          )}
          <p className="network-title text-main ml-3">{entry?.sourceNetwork?.chainName}</p>
        </div>

        <div className="main-target d-flex align-items-center">
          <Icon className="mr-1" name="arrow-right-long" color="brand-secondary" size={18} />
          <Icon name={entry?.targetNetwork?.chainIcon} size={20} />
          <p className="target-title text-main ml-3">{entry?.targetNetwork?.chainName}</p>
        </div>
      </div>

      <div className="amount-wrapper d-flex align-items-center justify-content-between ">
        <div
          className="token-amount-wrapper text-main ml-3 pl-1"
          title={entry.bridgedToken.amount}
          style={{ cursor: 'pointer' }}
        >
          {trimmedNumber}
        </div>
        <div className="d-flex align-items-center">
          {entry?.bridgedToken?.details?.icon && (
            <Icon name={entry?.bridgedToken?.details?.icon} size={20} className="mx-3" />
          )}
          <p className="text-secondary">{tokenSymbolName}</p>
        </div>
        <Tooltip text={t('claimPage:successModal.addTokenToWallet')}>
          <div
            className="d-flex align-items-center pointer ml-2"
            onClick={async () =>
              await addBridgedTokenToWallet('ERC20' as unknown as MetamaskAssetParamsType, {
                address: '',
                symbol: entry.bridgedToken.details.symbol,
                decimals: entry.bridgedToken.details.decimals,
              })
            }
          >
            <Icon name={'wallet-add-token'} color={'brand-primary'} size={16} />
          </div>
        </Tooltip>
      </div>
      <div className="claim-status d-flex flex-row-reverse ml-3">
        <ClaimButton
          disabled={
            !entry.majorityReached ||
            (entry.transmission.transmissionType === 'ccip' && !entry.failed)
          }
          status={
            entry.status === 'true'
              ? t('claimPage:buttonStatus.claimed')
              : t('claimPage:buttonStatus.notClaimed')
          }
          clicked={setButtonStatus}
          entry={entry}
        />
      </div>
    </div>
  );
};

export default ClaimListingWeb;
